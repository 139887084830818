<template>
  <div>
    <div v-if="this.company" class="menu-items">
      <a
       href="/integr/cardTransactions"
        class="cardTransactions cursor-pointer"
        :class="this.menuSelected.cardTransactions && 'cardTransactions-active'"
        >{{ $t("cardTransactionsName") }}</a
      >
      <a
      href="/integr/account"
      class="amanage cursor-pointer"
        :class="this.menuSelected.account && 'amanage-active'"
        >{{ $t("settingsName") }}</a
      >
      <a
      href="/integr/contact"
      class="contact cursor-pointer"
        :class="this.menuSelected.contact && 'contact-active'"
        >{{ $t("contactName") }}</a
      >

      <a href="#" class="logout" @click="logout()">{{ $t("logoutName") }}</a>
    </div>
    <div class="socials">
      <a href="https://www.facebook.com/UpRomania" class="facebook"></a>
      <a
        href="https://www.linkedin.com/company/up-romania"
        class="linkedin"
      ></a>
      <a
        href="https://www.youtube.com/c/UpRomaniaBucuresti"
        class="youtube"
      ></a>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "MenuItemsIntegrator",
  props: {
    company: Object,
    menuSelected: {
      cardTransactions: false,
      account: false,
      contact: false
    },
    user: Object
  },
  methods: {
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("user_role");
      Cookies.remove("token");
      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
      this.$emit("sendUserRole", "", "");
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
